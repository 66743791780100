<template lang="pug">
nuxt-link.startpage-rolfs_info-grouptravel.px-3(
  v-if="locale !== 'sv'"
  :to="localeURLs['info-grouptravel']"
)
  MediaWithText.w-100(
    :critical="true"
    :media="{ location: imageByLocale, mediatype: 'image' }"
    t-title="frontPage.pitchTitle"
    t-subtitle="frontPage.pitchSubtitle"
    t-ending-text="frontPage.pitchBy"
  )
    template(#description)
      p.mb-3(data-i18n="frontPage.pitchDescriptionRow1") {{ $t('frontPage.pitchDescriptionRow1') }}
      p.mb-3(data-i18n="frontPage.pitchDescriptionRow2") {{ $t('frontPage.pitchDescriptionRow2') }}

  .d-flex.justify-content-center.mt-4
    button.btn.btn-lg.btn-blue.bg-medium-blue.rounded-5.text-transform-none
      h4.mb-0.font-weight-normal {{ $t('frontPage.pitchButton') }}
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),

    imageByLocale () {
      return this.locale === 'sv' ? 'campaign/rolfs-grouptravel-banner' : 'shutterstock_2399743963_s3xpaq'
    },
  },
})
</script>

<style lang="scss" scoped>

</style>
